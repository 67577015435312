import cx from "classnames";
import React, { CSSProperties, useCallback, useEffect, useRef } from "react";
import styles from "./drawer.module.scss";

export interface IDrawer {
  children?: React.ReactNode;
  show?: boolean;
  onDismiss: () => void;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const Drawer = (props: IDrawer) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (props.show) {
      if (!ref.current?.open) {
        ref.current?.showModal();
      }
    } else {
      if (ref.current?.open) {
        ref.current?.close();
      }
    }
  }, [props.show]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDialogElement>) => {
      if (e.target === ref.current) props.onDismiss();
    },
    [ref, props.onDismiss]
  );

  return (
    <dialog
      className={cx(styles.drawer, props.show && styles.show, props.className)}
      style={props.style}
      id={props.htmlId}
      ref={ref}
      onClick={onClick}
    >
      <div className={styles.inner}>{props.children}</div>
    </dialog>
  );
};

Drawer.defaultProps = {};
