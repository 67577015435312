import cx from "classnames";
import React, { CSSProperties, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { useComposedRefs, useHandleResize } from "shared/lib";
import { Drawer } from "shared/ui/drawer";
import { Portal } from "shared/ui/menu/lib/portal";
import { useDropdownContext, useDropdownPopover } from "../../lib/dropdown";
import { MenuItems } from "../../lib/menu-button";
import styles from "./menu.module.scss";

export interface IMenu {
  width?: number;
  minWidth?: number;
  arrow?: boolean;

  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Menu = ({ width, minWidth, children, arrow, ...props }: IMenu) => {
  let {
    popoverRef,
    state: { isExpanded },
    placement,
    referenceElement,
    dispatch,
  } = useDropdownContext("Menu");

  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>();

  const modifiers = useMemo(() => {
    if (arrow) {
      return [
        { name: "arrow", options: { element: arrowElement } },
        { name: "offset", options: { offset: [0, 11] } },
      ];
    }

    return [{ name: "offset", options: { offset: [0, 4] } }];
  }, [arrowElement, arrow]);

  const popper = usePopper(referenceElement, popperElement, { placement, modifiers });

  const ref = useComposedRefs(setPopperElement, popoverRef);
  useDropdownPopover();

  const mobile = useHandleResize() <= 600;

  return (
    <Portal>
      {isExpanded && (
        <div
          ref={ref}
          className={styles.wrapper}
          style={{ width, minWidth, ...popper.styles.popper }}
          {...popper.attributes.popper}
        >
          {arrow && (
            <div
              ref={setArrowElement}
              className={styles.arrow}
              style={popper.styles.arrow}
              {...popper.attributes.arrow}
            />
          )}

          {mobile && (
            <Drawer show onDismiss={() => dispatch({ type: "CLOSE_MENU" })}>
              <MenuItems className={cx(styles.menu, styles.no_border, props.className)}>{children}</MenuItems>
            </Drawer>
          )}

          {!mobile && <MenuItems className={cx(styles.menu, props.className)}>{children}</MenuItems>}
        </div>
      )}
    </Portal>
  );
};

Menu.defaultProps = {
  arrow: true,
};
