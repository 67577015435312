import React, { forwardRef } from "react";
import { Button, IButton } from "shared/ui/button";
import { MenuButton as ReachMenuButton } from "../../lib/menu-button";

export interface IMenuButton extends IButton {}

export const MenuButton = forwardRef<HTMLButtonElement, IMenuButton>((props: IMenuButton, ref) => {
  return <ReachMenuButton as={Button as any} {...props} ref={ref} />;
});

MenuButton.defaultProps = {};
