import cx from "classnames";
import React, { CSSProperties } from "react";
import { MenuItem as ReachMenuItem } from "../../lib/menu-button";
import styles from "./menu-item.module.scss";

export interface IMenuItem {
  children?: React.ReactNode;
  onClick?: () => void;
  type?: "default" | "error";
  disabled?: boolean;

  prefix?: React.ReactNode;
  suffix?: React.ReactNode;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const MenuItem = (props: IMenuItem) => {
  return (
    <ReachMenuItem
      className={cx(styles.menu_item, props.type === "error" && styles.error, props.className)}
      style={props.style}
      id={props.htmlId}
      disabled={props.disabled}
      onSelect={() => props.onClick?.()}
    >
      {props.prefix && <span className={styles.prefix}>{props.prefix}</span>}
      <span>{props.children}</span>
      {props.suffix && <span className={styles.suffix}>{props.suffix}</span>}
    </ReachMenuItem>
  );
};

MenuItem.defaultProps = {};
