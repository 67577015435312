import cx from "classnames";
import React, { forwardRef, InputHTMLAttributes, useImperativeHandle, useRef, useState } from "react";
import styles from "./input.module.scss";

export interface IInput extends Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "prefix"> {
  label?: React.ReactNode;

  prefix?: React.ReactNode;
  suffix?: React.ReactNode;

  prefixStyling?: boolean;
  suffixStyling?: boolean;

  noInlineSuffix?: boolean;

  size?: "small" | "medium" | "large";
}

export const Input = forwardRef<HTMLInputElement, IInput>((props: IInput, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);

  const { value, size, className, style, id, prefix, suffix, prefixStyling, suffixStyling, ...restProps } = props;

  const [focus, setFocus] = useState(false);

  const canInlineSuffix =
    !props.noInlineSuffix && props.readOnly && props.suffix && typeof props.suffix === "string" && !props.suffixStyling;

  const onClick = () => {
    innerRef.current?.focus();
  };

  const onFocus: typeof props.onFocus = (e) => {
    setFocus(true);
    props.onFocus?.(e);
  };

  const onBlur: typeof props.onBlur = (e) => {
    setFocus(false);
    props.onBlur?.(e);
  };

  return (
    <div
      className={cx(
        styles.input,
        size === "small" && styles.small,
        size === "medium" && styles.medium,
        size === "large" && styles.large,
        prefix && styles.with_prefix,
        suffix && styles.with_suffix,
        !prefixStyling && prefix && styles.no_prefix_styling,
        !suffixStyling && suffix && styles.no_suffix_styling,
        focus && styles.focus,
        props.readOnly && styles.readonly,
        className
      )}
      onClick={onClick}
      style={style}
      id={id}
    >
      {prefix && <div className={cx(styles.prefix, !prefixStyling && styles.no_styling)}>{prefix}</div>}
      <input
        type="text"
        value={canInlineSuffix ? `${value || ""} ${suffix}` : value}
        {...restProps}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={innerRef}
      />
      {suffix && !canInlineSuffix && (
        <div className={cx(styles.suffix, !suffixStyling && styles.no_styling)}>{suffix}</div>
      )}
    </div>
  );
});

Input.defaultProps = {
  prefixStyling: true,
  suffixStyling: true,
};
