import { Placement } from "@popperjs/core";
import cx from "classnames";
import React, { CSSProperties } from "react";
import { Menu as ReachMenu } from "../../lib/menu-button";
import styles from "./menu-container.module.scss";

export interface IMenuContainer {
  children: React.ReactNode;
  placement: Placement;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const MenuContainer = (props: IMenuContainer) => {
  // const [referenceElement, setReferenceElement] = React.useState<any>(null);
  // const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  // const popper = usePopper(referenceElement, popperElement);

  return (
    <ReachMenu
      placement={props.placement}
      className={cx(styles.menu_container, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      {props.children}
    </ReachMenu>
  );
};

MenuContainer.defaultProps = {
  placement: "bottom-start",
};
